import React from "react"
import { I18nProvider } from "@lingui/react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import catalogBR from "../locales/br/messages"
import Layout from "../components/layout"
import cto from "../assets/images/home/cto.png"
import cfo from "../assets/images/home/cfo.png"
import coo from "../assets/images/home/coo.png"
import ceo from "../assets/images/home/ceo.png"
import { FaLink, FaMapMarkerAlt } from 'react-icons/fa';
import quintoandar from "../assets/images/home/quintoandar.png"
import ifood from "../assets/images/home/ifood.png"
import codacy from "../assets/images/home/codacy.png"
import uniplaces from "../assets/images/home/uniplaces.png"
import unbabel from "../assets/images/home/unbabel.png"
import nubank from "../assets/images/home/nubank.png"

export default ({ data }) => (
  <I18nProvider
    language="br"
    catalogs={{
      br: catalogBR,
    }}
  >
    <Layout
      pageType="home"
      image="/android-chrome-512x512.png"
      lang="br"
      title="Startupedia, a enciclopédia das startups"
      description="Startupedia coloca à sua disposição todas as informações indispensáveis sobre startups e empreendedorismo com notícias e artigos sobre o mundo da start-up"
      paths={{
        en: "/en",
        it: "/it",
        br: "/br",
        fr: "/fr",
        de: "/de",
        es: "",
      }}
    >
      <div className="wrapper mt-3">
        <div className="container">
          <h1>A Enciclopédia de Startups e Empreendedorismo</h1>
          <p>Startup é uma palavra que vem do inglês e cujo significado literal é "startup". Mas o que é exatamente uma Startup, que tipos de Startups existem? Todas estas perguntas e muito mais abaixo.</p>
          
          <h2 className="pt-3">O que é uma Startup?</h2>
          <p>O termo Startup vem do inglês e sua tradução literal é "start-up".
          Embora não exista uma definição oficial, pode-se dizer que uma Startup é uma empresa ou iniciativa empreendedora com as seguintes características
            <ul>
              <li>se baseia na tecnologia</li>
              <li>é escalável</li>
              <li>opera em um ambiente de máxima incerteza</li>
            </ul>
            Além dos pontos acima, uma empresa iniciante difere de uma empresa clássica ou PME, por ser muito dinâmica e se adaptar rapidamente às mudanças.
            A criação deste tipo de empresas tecnológicas deu origem ao conceito de "startupismo" ou empreendedorismo que nada mais é do que tudo o que gira em torno deste mundo de criação de empresas que revolucionam o setor em que elas aparecem. Clique aqui para saber mais sobre <Link to="/br/o-que-e/startup/">o que são startups</Link>.
          </p>

          <h2 className="pt-3">Papéis e responsabilidades em uma Startup</h2>
          <p>Na fundação, ou constituição, deste tipo de empresa, participam dois ou três fundadores com perfis diferentes. Cada um deles traz sua experiência para que a empresa tenha sucesso. Aqui estão os papéis típicos em uma Startup.</p>
          <div className="row">
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/br/o-que-e/ceo-diretor-geral-chief-executive-officer/"><img src={ceo} alt="CEO" title="CEO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/br/o-que-e/cto-diretor-de-tecnologia-chief-technology-officer/"><img src={cto} alt="CTO" title="CTO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/br/o-que-e/cfo-diretor-financeiro-chief-financial-officer/"><img src={cfo} alt="CFO" title="CFO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/br/o-que-e/coo-diretor-de-operacoes-chief-operating-officer/"><img src={coo} alt="COO" title="COO" /></Link>
            </div>
          </div>
          
          <h2 className="pt-3">Lean Startup</h2>
          <p>O Método Lean Startup foi criado por Eric Ries e desenvolvido em seu livro "The Lean Startup". 
            Esta metodologia é utilizada por empresas em todo o mundo para abordar novas idéias de negócios, compreender o mercado e saber dimensioná-lo. 
            Seu objetivo é encurtar os ciclos de desenvolvimento de produtos e descobrir rapidamente se um modelo de negócios proposto é viável.
            Isto é conseguido adotando uma combinação de experimentação orientada por hipóteses comerciais, lançamentos de produtos iterativos e aprendizagem validada.
            Hoje é muito difícil levantar dinheiro, especialmente no estágio inicial, sem entender e implementar o Lean Startup. 
            Saiba mais sobre <Link to="/br/o-que-e/lean-startup/">o que é Lean Startup</Link>.</p>
          
          <h2 className="pt-3">Tipos de Startups por seu modelo de negócio</h2>
          <p>Startups, e qualquer outro tipo de empresa, resolvem um problema específico para um grupo de usuários.
            Dependendo do setor em que o problema for resolvido, as startups podem ser divididas, entre outros, em
            <ul>
              <li><strong>Startups Fintech:</strong> desenvolvem sua solução aplicando <Link to="/br/o-que-e/fintech-tecnologia-financeira/">tecnologia financiera</Link>. Ejemplo: Nubank</li>
              <li><strong>Startups Foodtech:</strong> dedicam-se a melhorar os processos relacionados aos alimentos. Exemplo: Nutrebem</li>
              <li><strong>Startups Legaltech:</strong> seu mercado é a legislação e a lei. Exemplo: LegalNow</li>
              <li><strong>Startups Healhtech:</strong> focado principalmente no setor de cuidados com idosos. Exemplo: MedPass</li>
              <li><strong>Startups Cleantech:</strong> automatizar processos relacionados à limpeza têxtil e doméstica. Exemplo: ECO2Blocks</li>
              <li><strong>Startups Edtech:</strong> criar soluções inovadoras no setor da educação. Exemplo: Udemy</li>
              <li><strong>Startups Insurtech:</strong> oferecem serviços a seus clientes relacionados a seguros. Exemplo: Youse Seguros</li>
              <li><strong>Startups Proptech:</strong> desenvolver tecnologia voltada para o setor imobiliário. Exemplo: Decorati</li>
            </ul></p>

            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <ins className="adsbygoogle"
                style={{display:"block", textAlign:"center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-8800979330857975"
                data-ad-slot="6169923305"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            
          <h2 className="pt-3">Classificação das partidas por país</h2>
          Existem startups inovadoras em todo o mundo. Aqui estão alguns exemplos de Startups classificados por seus países de origem.
          
          <h3>Startups brasileiras</h3>
          <p>Com mais de 200 milhões de pessoas e uma economia em rápido crescimento, o Brasil é um país quente para iniciantes que buscam agressivamente a inovação e a participação no mercado. 
          O Brasil é um mercado interessante para empresas que buscam desenvolver novos modelos de negócios e desenvolver um reduto de negócios para expansão na América Latina.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={quintoandar} alt="quintoandar" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>QuintoAndar</strong><br/>
                  marketplace <br/>
                  <FaLink /> https://www.quintoandar.com.br &nbsp; <FaMapMarkerAlt /> Brasilia <br/>
                  Mercado de aluguel de apartamentos
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={ifood} alt="ifood" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>iFood</strong><br/>
                  food delivery <br/>
                  <FaLink /> https://www.ifood.com.br &nbsp; <FaMapMarkerAlt /> Brasilia <br/>
                  Ele permite que os usuários encomendem facilmente alimentos on-line de uma grande variedade de cozinhas para serem entregues em casa, operando entre parceiros de entrega, restaurantes e clientes.
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={nubank} alt="nubank" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Nubank</strong><br/>
                  financial services <br/>
                  <FaLink /> https://nubank.com.br &nbsp; <FaMapMarkerAlt /> Brasilia <br/>
                  Empresa que fornece soluções de pagamento, cartões de crédito gratuitos e investimentos de capital a clientes brasileiros
                </p>
              </div>
            </div>
          </div>
        

        <h3>Startups portugueses</h3>
          <p>Portugal tem tido uma série de grandes histórias de sucesso como o serviço de tradução Unbabel, o mercado de acomodações estudantis Uniplaces e a plataforma de revisão de código Codacy.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={unbabel} alt="unbabel" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Unbabel</strong><br/>
                  marketing <br/>
                  <FaLink /> http://www.unbabel.com &nbsp; <FaMapMarkerAlt /> Lisboa <br/>
                  Serviço online de tradução automática corrigida por humanos
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={uniplaces} alt="uniplaces" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Uniplaces</strong><br/>
                  real estate <br/>
                  <FaLink /> http://www.uniplaces.com &nbsp; <FaMapMarkerAlt /> Lisboa <br/>
                  Mercado para acomodação de estudantes
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={codacy} alt="codacy" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Codacy</strong><br/>
                  software <br/>
                  <FaLink /> https://www.codacy.com &nbsp; <FaMapMarkerAlt /> Lisboa <br/>
                  Ferramenta automatizada de revisão de código
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <h2>Últimos artigos do Blog</h2>
          <div className="row">
            {/*<div className="col-sm-12 col-lg-9 col-xl-9">*/}
            <div className="col-12">
              <div className="container">
                <div className="row">
                  {data.allContentfulBlogPost.edges
                    .slice(0, 10)
                    .map(({ node }, index) => {
                      return (
                        <>
                          {/*<div className="col-md-6 mt-5">*/}
                          <div className="col-md-6 col-lg-4 mt-5">
                            <Link
                              to={`${getBlogPath(node.lang)}/${node.slug}/`}
                              title={`${getBlogPath(node.lang)}/${node.slug}/`}
                              style={{
                                textDecoration: "none",
                                color: "dimgrey",
                                fontSize: "0.9rem",
                              }}
                            >
                              <div className="card">
                                <Img
                                  alt={node.heroImage.title}
                                  title={node.heroImage.title}
                                  style={{ maxWidth: 450, maxHeight: 253 }}
                                  fadeIn="false"
                                  loading="eager"
                                  fluid={node.heroImage.fluid}
                                />
                                <div className="card-body">
                                  <h2 className="card-title">{node.title}</h2>
                                  <p className="card-text">{node.excerpt}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 col-xl-3 mt-5 d-none">
              <img
                src="https://placehold.co/300x600"
                title="bla bla bla"
                alt="bla bla bla"
              />
              <div>&nbsp;</div>
              <img
                src="https://placehold.co/300x250"
                title="bla bla bla"
                alt="bla bla bla"
              />
            </div>
          </div>
          <p className="text-center mt-3"><Link to="/br/blog/"><strong>Veja mais artigos sobre startups</strong></Link></p>
        {/* </div>
      </div>
      <div className="col-12" style={{backgroundColor:"#283747"}}>
              <div className="row" style={{color:"white"}}>
                <div className="container">
                  <h2 style={{color:"white",marginTop:"10px"}}>Términos Populares</h2>
                  <p style={{color:"darkgray"}}>ola ka ase</p>
                </div>
                 */}
              </div>
            </div>
    </Layout>
  </I18nProvider>
)

export const query = graphql`
  query {
    allContentfulBlogPost(
      limit:6
      sort: { order: DESC, fields: updatedAt }
      filter: { lang: { eq: "br" } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            fluid(
              maxHeight: 253
              maxWidth: 450
              quality: 60
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}
